@import "../theme.scss";
@import "../common.scss";

.uniswap.page {
  .container {
    display: grid;
    grid-template-rows: auto auto 1fr;
    grid-template-columns: 1fr 450px;
    position: relative;

    .title {
      font-size: 24px;
      color: $accent-color;
      font-weight: 600;
      padding-bottom: 8px;
      border-bottom: 1px solid $border-color;

      grid-column-start: 1;
      grid-column-end: 99;
    }

    .overview {
      display: grid;
      grid-template-columns: 1fr auto;
      grid-column: 1;
      grid-row: 2;
      position: relative;

      .profits {
        min-width: 360px;

        .times {
          display: flex;

          > * {
            margin-right: 12px;
            opacity: 0.5;
            cursor: pointer;
            font-size: 15px;
          }

          .selected {
            opacity: 1;
            font-weight: 600;
          }
        }

        .profit-value {
          .amount {
            font-size: 72px;
            margin-right: 12px;
          }

          .unit {
            font-weight: 600;
            font-size: 20px;
          }
        }

        .pools {
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;
          align-self: flex-start;
          margin-top: 12px;
          justify-content: flex-start;

          .pair {
            font-size: 12px;
            color: white;
            display: grid;
            align-items: center;
            margin-right: 12px;
            margin-bottom: 16px;
            width: 105px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            grid-template-columns: 24px 1fr;

            img {
              width: 16px;
              height: 16px;
              display: inline-block;
              margin-right: 8px;
            }

            &.hide {
              display: none;
            }
          }
        }

        padding-top: 16px;
        margin-right: 12px;
      }

      .update-time {
        position: absolute;
        pointer-events: none;
        right: 12px;
        top: 18px;
        font-size: 12px;
        color: #999;
      }

      .charts {
        margin-left: -125px;
        margin-bottom: -24px;
        margin-right: -64px;

        width: 520px;
        height: 300px;
        position: relative;

        svg {
          path {
            transition: all 0.5s;
          }

          text {
            transition: all 0.5s;
          }
        }

        .loader {
          position: absolute;
          right: 240px;
          top: 100px;
        }
      }
    }

    .pools {
      grid-column: 1;
      grid-row: 3;

      table {
        tbody {
          tr {
            td.market {
              .title {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 13px;
                font-weight: 400;
                border: none;
                padding: 0;
                color: white;

                img {
                  width: 16px;
                  height: 16px;
                  display: inline-block;
                  margin-right: 12px;
                }
              }
            }
          }
        }
      }
    }

    .details-container {
      grid-row-start: 2;
      grid-row-end: 99;
      grid-column: 2;
      padding-top: 16px;

      .pool-details {
        padding-inline-start: 36px;
        font-size: 13px;
        position: sticky;
        top: 16px;

        > div {
          margin-bottom: 8px;
          display: flex;
          justify-content: space-between;

          > span:first-child {
            font-weight: 600;
          }
        }

        .title {
          border: none;
          font-size: 24px;
        }
      }

      .volumes-chart {
        display: flex;
        flex-direction: column;
        margin-left: -29px;
        margin-top: 24px;
        // background-color: yellow;

        div:first-child {
          padding-inline-start: 29px;
          margin-bottom: 12px;
          font-weight: 600;
        }
      }
    }
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #2e2f4f;
  color: white;

  #root {
    min-height: 100vh;
    display: grid;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.page {
  padding: 16px 5%;
  max-width: 1420px;
  // margin: 0 auto;
}

.down {
  color: red;
  transition: color 0.5s;
}

.up {
  color: #09e639;
  transition: color 0.5s;
}

.hide {
  display: none;
}

@import "../theme.scss";

@import url("https://fonts.googleapis.com/css2?family=Questrial&display=swap");

.landing.page {
  display: flex;
  justify-content: center;
  align-items: center;

  .container {
    display: grid;
    grid-template-rows: auto auto;

    .title {
      font-size: 32px;
      font-weight: 500;
      color: $accent-color;
      font-family: "Questrial", sans-serif;
      margin-bottom: 24px;
    }

    .wallets {
      .wallet {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        cursor: pointer;
        justify-content: space-between;
        color: white;
        border: 1px solid $accent-color;
        border-radius: 10px;
        padding: 8px 16px;
        font-size: 22px;
        transition: all 0.3s;

        img {
          width: 42px;
          display: inline-block;
          margin-right: 12px;
        }

        &:hover {
          opacity: 0.55;
        }

        &.metamask {
          color: #f6851b;
          border-color: #f6851b;
        }
      }
    }
  }
}

@import "../theme.scss";

.navbar {
  display: grid;
  grid-template-rows: auto 1fr;
  width: 64px;
  position: sticky;
  top: 0;

  .logo {
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 64px;
    img {
      width: 22px;
    }
  }

  .menu {
    padding: 24px 0;

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 64px;
      height: 64px;
      cursor: pointer;

      img {
        opacity: 0.45;
        width: 25px;
        height: 25px;
        transition: opacity 0.3s;
      }

      &:hover {
        img {
          opacity: 1;
        }
      }

      &.active {
        background-color: rgba(255, 255, 255, 0.15);
        img {
          opacity: 1;
        }
      }
    }
  }
}

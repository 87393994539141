table {
  border-collapse: collapse;
  padding: 0;
  width: 100%;
  text-align: center;
  table-layout: fixed;

  thead {
    font-size: 12px;
    color: #999;
    border-bottom: 1px solid $border-color;
    line-height: 1.5rem;

    th {
      font-weight: 500;
    }

    th:first-child {
      text-align: start;
      padding-left: 40px;
    }
  }

  tbody {
    tr {
      cursor: pointer;
      color: #fff;
      font-size: 13px;

      td {
        padding: 16px 0;
      }

      td:first-child {
        display: flex;
        align-items: center;
        padding-left: 12px;
        font-weight: 400;

        div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 13px;
          font-weight: 400;
          border: none;
          padding: 0;
          color: white;

          img {
            width: 16px;
            height: 16px;
            display: inline-block;
            margin-right: 12px;
          }
        }
      }

      &:hover {
        background-color: #8884d842;
      }
    }
  }
}

@import "../theme.scss";
@import "../common.scss";

.dashboard.page {
  display: grid;
  grid-template-rows: auto 1fr;

  .title {
    font-size: 24px;
    color: $accent-color;
    font-weight: 600;
    padding-bottom: 8px;
    border-bottom: 1px solid $border-color;
  }

  .label {
    font-size: 15px;
    font-weight: 500;
    text-transform: capitalize;
    color: #aaa;
  }

  .content {
    display: grid;
    grid-template-columns: 1fr 42px 1fr;
    padding-top: 16px;

    .portfolio {
      grid-column: 1;
      grid-row: 1;

      .balance {
        .value {
          font-size: 72px;
          display: inline-block;

          > span:first-child {
            margin-right: 12px;
          }

          .unit {
            font-weight: 600;
            font-size: 20px;
          }
        }
      }

      .assets {
        margin-top: 24px;

        .loading-container {
          display: flex;
          justify-content: center;
          width: 100%;
          padding: 12px;
        }
      }
    }

    .slide {
      grid-column: 3;

      .charts {
        display: flex;
        justify-content: center;
        align-items: center;
        align-items: flex-start;
        margin-bottom: -12px;
      }

      .shortcuts {
        .dapps {
          margin-top: 24px;

          .category {
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            a {
              text-decoration: none;
              color: white;
              font-size: 16px;
              font-weight: 500;
              transition: all 0.5s;

              &:hover {
                opacity: 0.5;
              }
            }

            .brand {
              // width: 100px;
              margin-right: 32px;
              margin-bottom: 32px;
              display: flex;
              align-items: center;

              img {
                display: inline-block;
                height: 27px;
                margin-right: 12px;
              }

              img.small {
                height: 16px;
              }
            }
          }
        }
      }
    }
  }
}
